<template>
  <div class="d-flex align-items-center justify-content-end">
    <!-- Fiyat Alanı -->
    <span v-bind:class="getClass" v-tooltip="getConvertedPrice">
      {{ currentPrice | moneyFormat(currentPriceCurrency) }}
    </span>
    <!-- ./Fiyat Alanı -->

    <!-- Fiyat Çekilemedi -->
    <template v-if="row.status == 'error'">
      <div class="ml-2 d-flex align-items-center">
        <button
          class="btn btn-outline-secondary border-0 btn-mini"
          v-tooltip.bottom="tooltipOptions"
          v-on:click="onClick"
        >
          <i class="fas fa-sync"></i>
        </button>
      </div>
    </template>
    <!-- ./Fiyat Çekilemedi -->
  </div>
</template>
<script>
export default {
  name: "TablePriceTooltip",
  props: {
    row: {
      default: () => {},
    },
    currentPrice: {
      default: 0,
    },
    currentPriceCurrency: {
      default: null,
    },
    convertedPrice: {
      default: 0,
    },
    convertedPriceCurrency: {
      default: null,
    },
  },
  methods: {
    onClick() {
      this.$parent.$parent.$parent.$emit("on-action", {
        key: "retry",
        row: this.row,
      });
    },
  },
  computed: {
    getConvertedPrice() {
      let result = null;

      if (
        this.convertedPrice &&
        this.currentPriceCurrency !== this.convertedPriceCurrency
      ) {
        const formattedPrice = localization.getMoneyFormat(
          this.convertedPriceCurrency,
          this.convertedPrice
        );

        result = {
          content: formattedPrice,
          placement: "top",
        };
      }

      return result;
    },
    getClass() {
      let result = "text-dark";

      return result;
    },
    tooltipOptions() {
      return {
        content: "Fiyat Alınamadı. <br/> Yeniden Deneyin",
        placement: "bottom",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-mini {
  padding: 3px 9px;
  i {
    font-size: 11px;
  }
}
</style>